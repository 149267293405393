<template>
	<div class="remixi-icon">
		<a-input v-model:value="select_icon">
			 <template #addonAfter>
				 <div @click="showIcon" class="select-icon">选择图标</div>
			</template>
		</a-input>
		<a-modal v-model:visible="show" title="图标选择" @ok="sureSelectIcon" @cancel="closeThis" width="930px">
			<div class="remixicon-cont mb12">
				<div 
					class="remixicon-cont-item" 
					:class="select_icon==item?'remixicon-acitve':''" 
					@click="select_icon= item" 
					v-for="(item,index) in iconList" :key="index" >
					<i :class="item"></i>
					<div>{{item}}</div>
				</div>
			</div>
			<div class="remixi-icon-pager">
				<a-pagination
					:default-current="page"
					:total="total"
					:defaultPageSize="limit"
					@change="(e)=>{pagenationList(e)}"
				/>
			</div>
		</a-modal>
	</div>
</template>

<script>
import icons from '@/assets/js/reminxiicon'
export default{
	name:'com-remixi-icon',
	data(){
		return{
			iconList:[],
			allList:[],
			page:1,
			limit:48,
			total:0,
			all_page:0,
			select_icon:'',
			show:false,
		}
	},
	props:{
		value:{
			type:String,
		}
	},
	watch:{
		'value':{
			deep: true,
			immediate: true,
			handler(val){
				this.select_icon = val
			},
		},
	},

	methods:{
		showIcon(){
			if( this.allList.length == 0 ){
				this.allList = icons
				this.total = icons.length
				this.pagenationList(1)
			}
			this.show = true
		},
		
		pagenationList(page){
			let t = this ,{ limit,allList } = t , iconList = []
			let start = (page-1) * limit
			let len = parseInt(start) + parseInt(limit) 
			for (var i = start ; i < len; i++) {
				iconList.push(allList[i])
			}
			this.iconList = iconList
		},
		sureSelectIcon(){
			this.show = false
			this.$emit('changeValue',this.select_icon)
		},
		closeThis(){
			this.show = false
			this.$emit('changeValue',this.value )
		}
	}
}
</script>

<style lang="scss">
	.select-icon{
		cursor: pointer;
	}
	.remixicon-cont{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		.remixicon-cont-item{
			width: 100px;
			height: 80px;
			border: 1px solid #f4f4f4;
			margin-bottom: 8px;
			text-align: center;
			padding-top: 7px;
			margin: 4px;
			cursor: pointer;
			i{
				font-size: 20px;
			}
			div{
				font-size: 12px;
			}
		}
		
		.remixicon-cont-item:hover{
			border: 1px solid #409EFF;
		}
		.remixicon-acitve{
			border: 1px solid #409EFF;
		}
	}
	.remixi-icon-btn{
		width: 100%;
		text-align: center;
		margin-top: 16px;
	}
	.remixi-icon-pager{
		width: 100%;
		text-align: center;
	}
</style>
